import React from "react";

const Item = ({ option }) => {
  const iconsImages = require.context("../../../images/portfolio_services", true);
  const iconPath = name => iconsImages(name, true);

  return (
    <li className="portfolio-services__item" key={option.name}>
      <span className="portfolio-services__icon">
        <img
          src={iconPath(option.icon.replace("/src/images/portfolio_services", "."))}
          alt={option.name}
        />
      </span>
      <span className="portfolio-services__name">{option.name}</span>
    </li>
  );
};

export default Item;

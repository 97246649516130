import React, { useState } from "react";
import Slider from "react-slick";
import renderHTML from "../../helpers/renderHTML";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

const Blog2020 = ({ posts }) => {
  const [slideIndex, setSlideIndex] = useState(2);

  const blogSliderSettingsSettings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    beforeChange: (oldIndex, newIndex) => {
      setTimeout(() => {
        if (newIndex === 0) {
          // eslint-disable-next-line react/no-this-in-sfc
          setSlideIndex(2);
        } else if (newIndex === 1) {
          // eslint-disable-next-line react/no-this-in-sfc
          setSlideIndex(5);
        }
      }, 10);
    }
  };
  return (
    <section className="blog blog__background">
      <div className="section__header blog__header">
        <div className="section__title--small">Take a part of Panda</div>
        <h2 className="section__title--large">Knowledge</h2>
      </div>
      <div className="container">
        <div className="blog-grid">
          <Slider className="blog__slider" {...blogSliderSettingsSettings}>
            {posts.edges.map(post => {
              return (
                <a href={post.node.path} className="blog-single">
                  <div className="blog-single__content">
                    <div className="left-border" />
                    <div className="right-border" />
                    <div className="content-border" />
                    <div className="bottom-border" />
                    <div className="blog-single__wrapper">
                      <div className="blog-single__image">
                        <img
                          className="blog-image responsive-img lazyload"
                          alt={`${post.node.title} | pandagroup.co`}
                          src={
                            post.node.featured_media &&
                            post.node.featured_media.localFile.childImageSharp.resolutions.src
                          }
                        />
                      </div>
                      <div className="blog-single__text">
                        <h5
                          className="blog-single__title"
                          dangerouslySetInnerHTML={renderHTML(post.node.title)}
                        />
                        <span className="blog-single__date">{post.node.date}</span>
                        <div className="blog-single__description">
                          <p dangerouslySetInnerHTML={renderHTML(post.node.excerpt)} />
                        </div>
                      </div>
                      {post.node.tags !== null ? (
                        <div className="blog-single__tags">
                          {post.node.tags.map(tag => {
                            return (
                              <a href={tag.path} className="blog-single__tag">
                                #{tag.name}
                              </a>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="blog-single__button">
                      <span className="btn__cta btn__cta--green btn__cta--arrow btn__cta--inverse">
                        take a look
                      </span>
                    </div>
                  </div>
                </a>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className="showmore__button">
        <a href="/blog">
          <span className="btn__cta btn__cta--green btn__cta--arrow btn__cta--inverse">
            more knowledge
          </span>
        </a>
      </div>
      <ProgressBar slideIndex={slideIndex} />
    </section>
  );
};

export default Blog2020;

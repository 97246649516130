import React from "react";
import renderHTML from "../../../helpers/renderHTML";
import Item from "./Item";
import bgCase from "../../../images/case/bg-case.png";
import bgNotebook from "../../../images/case/notebook.png";

const Slide = ({ data, options, mainUrl, index }) => {
  const { id } = data;
  const dataText = ["happy", "satisfied", "glad", "appeased", "pleased"];

  const thumbnail = data.acf.mock_up_main // eslint-disable-line no-nested-ternary
    ? data.acf.mock_up_main
    : data.acf.mock_up_main_video
    ? data.acf.mock_up_main_video
    : null;

  return (
    <div key={id} className="portfolio-slide">
      <section className="portfolio-slide__column portfolio-slide__column--first">
        <span
          className="portfolio-slide__project"
          dangerouslySetInnerHTML={renderHTML(data.title)}
        />
        <span
          className="portfolio-slide__title"
          dangerouslySetInnerHTML={renderHTML(data.acf.short_title)}
        />
        <span
          className="portfolio-slide__desc"
          dangerouslySetInnerHTML={renderHTML(data.acf.short_text)}
        />
        <span className="portfolio-services__title portfolio-services__title--slide">
          {data.acf.what_wewe_done}?
        </span>
        <ul className="portfolio-services">
          {options.map(option => (
            <Item option={option} mainPageUrl={mainUrl} key={option.name} />
          ))}
        </ul>
        <div className="portfolio-slide__cta">
          <a
            className="btn__cta btn__cta--green btn__cta--arrow btn__cta--inverse"
            href={data.path}
          >
            take a look
          </a>
        </div>
      </section>
      <section className="portfolio-slide__column">
        <div className="portfolio-slide__preview lazyload" data-bg={bgCase}>
          {thumbnail != null ? (
            <div className="portfolio-slide__image">
              <img
                className="lazyload portfolio-slide__notebook"
                src=""
                data-src={bgNotebook}
                alt=""
              />

              {thumbnail.localFile ? (
                <img
                  className="lazyload portfolio-slide__thumbnail"
                  src=""
                  data-src={thumbnail.localFile.childImageSharp.original.src}
                  alt=""
                />
              ) : (
                /* eslint-disable-next-line jsx-a11y/media-has-caption */
                <video controls className="portfolio-slide__video">
                  <source
                    src={`${thumbnail.url.source_url}#t=0.5`}
                    type={thumbnail.url.mime_type}
                  />
                  Your browser does not support the video tag.
                </video>
              )}

              {data.acf.page_logo != null ? (
                <div className="portfolio-slide__client">
                  <span className="portfolio-slide-client__txt">
                    For our
                    <strong className="portfolio-slide-client__marked">{dataText[index]}</strong>
                    client:
                  </span>
                  <img
                    className="lazyload portfolio-slide-client__logo"
                    data-src={data.acf.page_logo.localFile.childImageSharp.original.src}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                    alt={data.acf.page_logo.alt_text}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default Slide;

import React from "react";

const HomePortfolio = props => {
  return (
    <>
      <section className="portfolio-homepage">
        <div className="section__header services__header">
          <div className="section__title--small">see our projects</div>
          <h2 className="section__title--large">case studies</h2>
        </div>
        <div className="portfolio-homepage__wrapper">{props.children}</div>
      </section>
    </>
  );
};
export default HomePortfolio;

import React from "react";

const AnniversaryBanner = () => {
  return (
    <section className="anniversary-banner">
      <img
        className="anniversary-image"
        /* eslint-disable-next-line import/no-unresolved,global-require */
        src={require("../../images/anniversary.webp")}
        alt="10th anniversary of Panda Group"
      />
      <p className="anniversary-text">
        For a decade, we have proudly served our clients with exceptional value and growth. Let’s
        celebrate this milestone together!
      </p>
    </section>
  );
};

export default AnniversaryBanner;

import React from "react";
import Slider from "react-slick";

const Certificates2020 = () => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 2100
        }
      }
    ]
  };

  return (
    <section className="certificates">
      <div className="container">
        <Slider className="slider__certificates" {...settings}>
          <div className="certificate">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../../images/certificates/solutions.png")}
              alt="Magento Certified - Solution Specialist | pandagroup.co"
            />
          </div>
          <div className="certificate">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../../images/certificates/developer.png")}
              alt="Magento Certified - Developer | pandagroup.co"
            />
          </div>
          <div className="certificate">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../../images/certificates/frontend.png")}
              alt="Magento Certified - Front-end Developer | pandagroup.co"
            />
          </div>
          <div className="certificate">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../../images/certificates/developer-plus.png")}
              alt="Magento Certified - Developer plus | pandagroup.co"
            />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Certificates2020;

import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../Layout/Layout";
import SEO from "../Layout/Seo";

import HomeBanner from "../modules/HomeBanner/HomeBanner2020";
import Services from "../modules/Services/Services";
import HomePortfolio from "../modules/HomePortfolio/HomePortfolio";
import Portfolio from "../modules/Portfolio/Portfolio2020";
import References2020 from "../modules/References2020/References2020";
import Certificates2020 from "../modules/Certificates/Certificates2020";
import Blog2020 from "../modules/Blog/Blog2020";
import CTA from "../modules/CTA/CTA";
import AnniversaryBanner from "../modules/AnniversaryBanner/AnniversaryBanner";

const PageHome2020Template = props => {
  const currentPage = props.data.wordpressPage;
  const services = props.data.allWordpressWpServices;
  const posts = props.data.allWordpressPost;
  const porfolio = props.data.allWordpressWpPortfolio;
  const metaTitle = currentPage.yoast.title || "";
  const metaDesc = currentPage.yoast.metadesc || "";
  const metaKeyWords = currentPage.yoast.metakeywords || "";

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "home"
        }}
      />
      <SEO title={metaTitle} keywords={metaKeyWords} description={metaDesc} />
      <HomeBanner
        title={currentPage.acf.banner_title_large}
        subtitle={currentPage.acf.banner_subtitle}
        text={currentPage.acf.banner_text}
        ctatext={currentPage.acf.banner_cta_text}
      />
      <Certificates2020 />
      <AnniversaryBanner />
      <CTA />
      <section className="services">
        <div className="section__header services__header">
          <div className="section__title--small">In these services</div>
          <h2 className="section__title--large">We are the Experts</h2>
        </div>
        <Services servicesData={services} />
      </section>
      <HomePortfolio>
        <Portfolio portfolioData={porfolio.nodes} homepageUrl={currentPage.link} />
      </HomePortfolio>
      <CTA />
      <References2020 data={props.data.allWordpressWpPortfolio} />
      <Blog2020 posts={posts} />
    </Layout>
  );
};

export default PageHome2020Template;

export const pageQuery = graphql`
  query currentHome2020PageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      link
      yoast {
        title
        metakeywords
        metadesc
      }
      date(formatString: "MMMM DD, YYYY")
      acf {
        banner_title_large
        banner_subtitle
        banner_text
        banner_cta
        banner_cta_text
      }
    }
    allWordpressWpPortfolio {
      nodes {
        id
        title
        link
        path
        acf {
          client_reference
          client_position
          client_name
          country_flag
          short_title
          project_logo {
            title
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          client_photo {
            id
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          mock_up_main {
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          mock_up_main_video {
            url {
              source_url
              mime_type
            }
          }
          page_logo {
            id
            source_url
            alt_text
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          services {
            icon
            name
          }
          short_text
          shop_link
          country_flag
          what_wewe_done
        }
        wordpress_id
      }
    }
    allWordpressWpServices(
      filter: { wordpress_id: { in: [36, 39, 45] } }
      sort: { order: ASC, fields: acf___service_icon_green___date }
    ) {
      edges {
        node {
          title
          wordpress_id
          link
          path
          acf {
            service_short_info
            service_icon {
              source_url
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressPage(
      filter: { wordpress_id: { in: [73, 75] } }
      sort: { fields: [wordpress_id], order: ASC }
    ) {
      nodes {
        slug
        id
        acf {
          cover_photo {
            source_url
          }
        }
      }
    }
    allWordpressPost(limit: 2) {
      edges {
        node {
          id
          title
          path
          excerpt
          date(formatString: "DD MMMM YYYY")
          featured_media {
            id
            source_url
            localFile {
              childImageSharp {
                original {
                  src
                }
                resolutions(height: 150) {
                  height
                  src
                }
              }
            }
          }
          tags {
            id
            count
            name
            path
          }
        }
      }
    }
  }
`;

import React, { useState } from "react";
import Slider from "react-slick";
import Slide from "./components/Slide";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

const Portfolio = props => {
  const allData = props.portfolioData;
  const data = allData.filter(portfolio => portfolio.wordpress_id !== 129);
  const mainUrl = props.homepageUrl;
  const [slideIndex, setSlideIndex] = useState(0);
  let index = -1;

  const sliderSettings = {
    arrows: true,
    // speed: 1500,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
          dots: false
        }
      }
    ],
    beforeChange: (oldIndex, newIndex) => {
      setTimeout(() => {
        setSlideIndex(newIndex);
      }, 10);
    }
  };

  return (
    <section className="portfolio-homepage__slider">
      {/* eslint-disable */}
      <Slider {...sliderSettings}>
        {data.map(slide => {
          index = index + 1;

          return (
            <Slide
              data={slide}
              index={index}
              key={data[1].id}
              options={slide.acf.services}
              mainUrl={mainUrl}
            />
          );
        })}
      </Slider>
      <div className="portfolio-homepage__progressbar">
        <ProgressBar slideIndex={slideIndex} />
      </div>
    </section>
  );
};

export default Portfolio;
